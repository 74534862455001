.primary-nav .active {
    font-weight: bold;
    text-decoration: underline;
}

.mobile-menu-open {
    background-color: #3F3F46;
    position: fixed;
    top: 5.2rem;
    right: 0;
    bottom: 0;
    left: 0;
}
